<template>
    <div>
        <el-carousel :height="computeHeight"
                     :autoplay="autoplay"
                     :initial-index="initialIndex"
                     @change="onChange">
            <el-carousel-item v-for="(item, index) in sliders" :key="index">
                <vue3VideoPlay
                        v-if="item.img.indexOf('mp4') !== -1"
                        ref="videoPlayer"
                        v-bind="options"
                        :height="computeHeight"
                        @play="onPlayerPlay($event)"
                        @ended="onPlayerEnded($event)"/>
                <img  v-else :src="item.img" class="slider_img">
            </el-carousel-item>
        </el-carousel>
        <div id="产品解决方案"  class="solve head-label">
            <div style="text-align: center;" class="font_size_24">
                产品解决方案
            </div>
        </div>
        <!-- 盒子 -->
        <div class="border_parent">
            <el-row :gutter="10">
                <el-col :xs="12" :sm="12" :md="6">
                    <div class="border">
                        <div class="border_img_parent">
                            <img :src="productSolutionsImages[0].img" class="img_border"/>
                        </div>
                        <div class="border_title">{{productSolutionsImages[0].title}}</div>
                        <div class="border_content_one">{{productSolutionsImages[0].content[0]}}</div>
                        <div class="border_content_two">{{productSolutionsImages[0].content[1]}}</div>
                    </div>
                </el-col>
                <el-col :xs="12" :sm="12" :md="6">
                    <div class="border">
                        <div class="border_img_parent">
                            <img :src="productSolutionsImages[1].img" class="img_border"/>
                        </div>
                        <div class="border_title">{{productSolutionsImages[1].title}}</div>
                        <div class="border_content_one">{{productSolutionsImages[1].content[0]}}</div>
                        <div class="border_content_two">{{productSolutionsImages[1].content[1]}}</div>
                    </div>
                </el-col>
                <el-col :xs="12" :sm="12" :md="6">
                    <div class="border">
                        <div class="border_img_parent">
                            <img :src="productSolutionsImages[2].img" class="img_border"/>
                        </div>
                        <div class="border_title">{{productSolutionsImages[2].title}}</div>
                        <div class="border_content_one">{{productSolutionsImages[2].content[0]}}</div>
                        <div class="border_content_two">{{productSolutionsImages[2].content[1]}}</div>
                    </div>
                </el-col>
                <el-col :xs="12" :sm="12" :md="6">
                    <div class="border" style="margin-right: 0">
                        <div class="border_img_parent">
                            <img :src="productSolutionsImages[3].img" class="img_border"/>
                        </div>
                        <div class="border_title">{{productSolutionsImages[3].title}}</div>
                        <div class="border_content_one">{{productSolutionsImages[3].content[0]}}</div>
                        <div class="border_content_two">{{productSolutionsImages[3].content[1]}}</div>
                    </div>
                </el-col>
            </el-row>
        </div>
        <!-- 手机端商品展示 -->
        <div id="微信商城平台" class="head-label">
            <div style="text-align: center;" class="font_size_24">
                {{products[0].title}}
            </div>
        </div>
        <div class="product_mobile">
            <el-row>
                <el-col :xs="24" :sm="6">
                    <el-carousel :height="computeHeight">
                        <el-carousel-item v-for="(item, index) in products[0].imgMobile" :key="index">
                            <img :src="item"  class="product_mobile_img">
                        </el-carousel-item>
                    </el-carousel>
                </el-col>
                <el-col :xs="24" :sm="18">
                    <div class="product_mobile_content">
                        <div class="product_mobile_title font_size_30">{{products[0].content}}</div>
                    </div>
                    <el-carousel :height="computeAdminHeight">
                        <el-carousel-item v-for="(item, index) in products[0].imgAdmin" :key="index">
                            <img :src="item"  class="product_admin_img">
                        </el-carousel-item>
                    </el-carousel>
                </el-col>
            </el-row>
        </div>
        <!-- 手机端商品展示 -->
        <div id="微信公众号管理平台" class="head-label">
            <div style="text-align: center;" class="font_size_24">
                {{manages[0].title}}
            </div>
        </div>
        <!-- 后台管理 -->
        <div class="product_pc">
            <div class="product_pc_one">
                <div style="margin-top: 20px" class="product_mobile_title font_size_30">{{manages[0].content[0]}}</div>
                <div style="margin-top: 10px" class="product_mobile_title font_size_30">{{manages[0].content[1]}}</div>
            </div>
            <el-carousel :height="computePCHeight">
                <el-carousel-item v-for="(item, index) in manages[0].img" :key="index">
                    <img :src="item"  class="product_pc_img">
                </el-carousel-item>
            </el-carousel>
        </div>
        <!-- 合作伙伴-->
        <!-- <div id="合作伙伴" class="cooperation head-label">
            <div style="text-align: center;" class="font_size_24">
                合作伙伴
            </div>
        </div> -->
        <!-- <div class="cooperation_parent">
            <div style="margin: 0 20px; text-align: center">
                <img style="height: 90px;width: 90px;border-radius: 50%;" src="../../static/other/logo.png"/>
                <div>上海鑫速亿</div>
            </div>
        </div> -->
        <!-- 关于我们-->
        <!-- <div id="关于我们" class="about_me head-label">
            <div style="text-align: center;" class="font_size_24">
                关于我们
            </div>
        </div> -->
        <div style="width: 90%;background-color: #F2F6FC;margin: 10px auto;">
            <div style="display: flex; flex-direction: column;width: 100%;">
                <p class="about_me_p">{{company[0]}}</p>
                <p class="about_me_p">{{company[1]}}</p>
            </div>
        </div>

    </div>
</template>
<script>
    import { reactive, ref } from "vue";
    export default {
        name: 'Index',
        setup() {
            let videoPlayer = ref(null);
            return { videoPlayer };
        },
        data() {
            return {
                autoplay: true, //自动播放
                initialIndex: 0, //从下标第几个开始轮播
                sliders: [],
                productSolutionsImages: [
                    {
                        img: require('../../static/other/技术咨询.png'),
                        title: "技术咨询",
                        content: ["微服务技术咨询", "物联网技术咨询"]
                    },
                    {
                        img: require('../../static/other/开发定制.png'),
                        title: "开发定制",
                        content: ["微信管理|微商城开发", "物联网平台|STM32开发"]
                    },
                    {
                        img: require('../../static/other/运维服务.png'),
                        title: "IT运维服务",
                        content: ["系统建设|日常维护", "问题处理|应急处置"]
                    },
                    {
                        img: require('../../static/other/数据分析.png'),
                        title: "数据分析",
                        content: ["交易量直观分析", "经营效果智能分析"]
                    }
                ],
                products: [
                    {
                        imgMobile: [require('../../static/other/wscsy.png'), require('../../static/other/wscfl.png'), require('../../static/other/wscgwc.png'), require('../../static/other/wscwd.png'), require('../../static/other/wscwd.png')],
                        imgAdmin: [require('../../static/other/wschdsy.png'),require('../../static/other/wschdhygl.png'), require('../../static/other/wschdspfl.png'), require('../../static/other/wschdspgl.png'), require('../../static/other/wschdddgl.png')],
                        title: "微信商城平台",
                        content: "手机端商品展示 满足多规格显示 商品直接在线购买支付"
                    }
                ],
                manages: [
                    {
                        img: [require('../../static/other/gzhzh.png'), require('../../static/other/gzhcd.png'), require('../../static/other/gzhfzgl.png'), require('../../static/other/gzhmbxx.png')],
                        title: "微信公众号管理平台",
                        content: ["支持多公众号接入", "提供公众号菜单、自动回复、公众号素材、简易CMS、等管理功能"]
                    }
                ],
                company: [
                    "企业经营范围：互联网软件开发，微信公众号，小程序，微商城开发，提供技术支持，是所创业型服务研发公司。",
                    "核心理念：提供软件一键式部署，实现简单可靠稳定的软件服务平台。",
                ],

                options: reactive({
                    width: "100%", //播放器宽度
                    //height: "500px", //播放器高度
                    color: "#409eff", //主题色
                    title: "碧华寻迹小车", //视频名称
                    src: require('../../static/video/xiaoche.mp4'), //视频源
                    poster: require('../../static/slider/xiaoche.png'),
                    muted: false, //静音
                    webFullScreen: false,
                    speedRate: ["0.75", "1.0", "1.25", "1.5", "2.0"], //播放倍速
                    autoPlay: true, //自动播放
                    loop: false, //循环播放
                    mirror: false, //镜像画面
                    ligthOff: false, //关灯模式
                    volume: 0.3, //默认音量大小
                    control: true, //是否显示控制
                    controlBtns: [
                        "audioTrack",
                        "quality",
                        "speedRate",
                        "volume",
                        "setting",
                        "pip",
                        "pageFullScreen",
                        "fullScreen",
                    ], //显示所有按钮,
                })
            }
        },
        methods: {
            // 监听轮播图改变
            onChange(index) {
                // 如果是视频,就等播放完再进行下一个
                if (this.sliders[index].img.indexOf("mp4") != -1 && this.options["src"] !=  this.sliders[index].img) {
                    this.options["src"] = this.sliders[index].img; //把获取来的视频赋值给sources的src
                    this.options["poster"] = this.sliders[index].poster;

                    this.videoPlayer.src = this.options["src"]; // 重置进度条,再次轮播时让视频重新播放
                    this.videoPlayer.poster = this.options["poster"];
                    this.autoplay = false;
                }
            },
            onPlayerEnded() {
                //视频播放停止时候触发的事件
                this.autoplay = true;
            },
            onPlayerPlay() {
                this.autoplay = false;
            },
        },
        mounted() {
            this.sliders = [
                {
                    poster: require("../../static/slider/xiaoche.png"),
                    img: require("../../static/video/xiaoche.mp4")
                },
                {
                    img: require("../../static/slider/wxsc.png")
                },
                {
                    img: require("../../static/slider/wxgl.png")
                }
            ]
        },
        computed: {
            //计算高度
            computeHeight() {
                let screenHeight = document.body.clientWidth;
                if (screenHeight > 415) {
                    return '550px';
                } else {
                    return '250px';
                }
            },
            computeAdminHeight() {
                let screenHeight = document.body.clientWidth;
                if (screenHeight > 415) {
                    return '450px';
                } else {
                    return '200px';
                }
            },
            computePCHeight() {
                let screenHeight = document.body.clientWidth;
                if (screenHeight > 415) {
                    return '500px';
                } else {
                    return '200px';
                }
            },
        },
    }
</script>

<style scoped>
    .border_parent {
        width: 90%;
        margin: auto;
    }

    .slider_img {
        height: 550px;
        width: 100%;
    }

    .solve {
        margin-top: 30px;
        margin-bottom: 10px
    }

    .border {
        border-radius: 6px;
        border: 1px solid #EBEEF5;
        height: auto;
        margin: 10px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1)
    }
    .border_img_parent {
        margin-top: 20px;
        display: flex;
        justify-content: center;
    }

    .product_mobile_content {
        height: 90px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .product_mobile_img {
        height: 550px;
        width: 100%;
    }
    .product_admin_img {
        height: 450px;
        width: 100%;
    }
    .product_mobile_title {
        font-weight: bold;
        text-align: center;
    }

    .product_pc_one {
        text-align: center;
        min-height: 100px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .font_size_30 {
        font-size: 1.875em;
    }

    .product_pc_img {
        height: 500px;
        width: 100%
    }

    .product_pc {
        width: 90%;
        align-items: center;
        margin: 10px auto;
        background: #F2F6FC;
    }

    .cooperation_parent {
        display: flex;
        justify-content: center;
        width: 90%;
        margin: 20px auto;
        align-items: center;
        height: auto
    }

    .about_me_p {
        font-size: 1.25em;
        font-weight: normal;
        padding: 0 20px;
        margin: 10px;
    }

    .border_title {
        font-family: "PingFang SC";
        margin-top: 10px;
        font-size: 1.125em;
        font-weight: bold;
        text-align: center;
    }

    .border_content_one {
        margin-top: 20px;
        font-size: 1.125em;
        text-align: center;
    }

    .border_content_two {
        margin-top: 5px;
        font-size: 1.125em;
        margin-bottom: 25px;
        text-align: center;
    }

    .img_border {
        margin-top: 15px;
        width: 80px;
        height: 80px
    }

    .cooperation {
        margin-top: 30px;
        width: 100%;
    }

    .about_me {
        display: flex;
        margin-top: 30px;
        justify-content: center;
        width: 100%;
    }

    .product_mobile {
        width: 90%;
        align-items: center;
        margin: 10px auto;
        background: #F2F6FC;
    }

    /* 屏幕设备适配 max-device-width: 400px */
    @media screen and (max-device-width: 415px) {
        .slider_img {
            height: 250px;
            width: 100%;
        }

        .border_parent {
            display: flex;
            width: 90%;
            margin: auto;
            height: auto;
            flex-direction: column;
        }

        .border {
            border-radius: 6px;
            border: 1px solid #EBEEF5;
            float: left;
            width: 100%;
            height: auto;
            margin: 10px 0 auto;
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1)
            /*background: aquamarine;*/
        }

        .border_img_parent {
            margin-top: 0;
        }

        .product_mobile {
            width: 90%;
            align-items: center;
            margin: 20px auto;
            height: auto;
            background: #F2F6FC;
        }

        .product_mobile_content {
            width: 100%;
            margin-top: 5px;
        }

        .product_mobile_img {
            float: left;
        }

        .product_mobile_title {
            font-weight: bold;
        }

        .product_pc_one {
            width: 100%;
            margin-top: 15px;
        }

        .font_size_30 {
            font-size: 1.25em;
        }
        .product_pc_img {
            height: 280px;
            width: 100%;
        }
        .product_pc {
            width: 90%;
            height: auto;
            margin: 20px auto;
            align-items: center;
            background: #F2F6FC;
        }

        .head-label {
            display: flex;
            margin-top: 10px;
            justify-content: center;
            align-items: center;
        }

        .border_title {
            font-family: "PingFang SC";
            margin-top: 5px;
            font-size: 1.125em;
            font-weight: bold;
        }

        .border_content_one {
            margin-top: 5px;
            font-size: 1.125em;
        }

        .border_content_two {
            margin-top: 5px;
            font-size: 1.125em;
            margin-bottom: 8px;
        }

        .img_border {
            margin-top: 8px;
            width: 80px;
            height: 80px
        }

        .about_me {
            display: flex;
            margin-top: 10px;
            width: 100%;
        }
    }

    .font_size_24 {
        font-size: 1.5em;
    }
</style>